
.cardscss{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;
    padding: 5rem;
}
.mainDiv1{
    width: 500px;
    overflow: hidden;
    border: 1px solid rgb(236, 236, 236);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    font-family:Arial, Helvetica, sans-serif;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
}
.cardTop1{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #9fcdff;
    color: black;
    text-align: center;
    padding: 20px;
}
.img1 img{
    width: 100%;
    height: 100%;
}
.img1{
    max-width: 100px;
}
.cardBottom1{
    background-color: #fff;
    padding: 10px;
}
.mainHeading1{
    margin-bottom: 4rem;
}
.scheduleHeading1{
    color: rgb(172, 170, 170);
    margin-bottom: 5px;
}
.cardBottom1 p {
    margin-bottom: 16px;
    font-weight: bold;
}
.viewDetails1{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
}
.link1 a, label{
    /* color: rgb(13, 100, 214); */
    text-decoration: none;
    font-weight: bold;
    font-size: large;
} 
.custom-checkbox1 {
    transform: scale(1.7);  
    cursor: pointer;
    margin-right: 0.7rem;
}
