.Egate_landing {
  width: 80%;
  margin: 0 auto;
}
/* popup css for maintainance div */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}
.popupPgcourses {
  background: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
}

.popupPgcourses button {
  margin-top: 10px;
  padding: 5px 10px;
  background-color: #007BFF;
  color: white;
  border: none;
  cursor: pointer;
}
.popup-overlayPgcourses {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}
.UG_Exams_button_container {
  display: flex;
  justify-content: flex-end;
}
.Ug_Exams_button {
  display: flex;
  justify-content: end;
  width: 110px;
  justify-content: center;
  padding: 10px;
  align-items: center;
  gap: 10px;
  background: #0f3d4f;
  color: #ffff;
  font-size: 22px;
}
.popup-content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  font-size: 18px;
}
.popup-content p {
  font-size: 20px;
  margin-bottom: 20px;
}

.popup-content button {
  margin-top: 10px;
  padding: 8px 16px;
  background: #ff4400;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 18px;
}

.popup-content button:hover  {
  background: #ff2600;
}
/* popupp maintainance div closed */

.OurOfferings_btn {
  padding: 10px;
  background: #ffff;
  border: 1px solid #79c6e5;
  font-size: 18px;
  border-radius: 10px;
  box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
  color: #00222c;
  
}

a.OurOfferings_btn {
  display:none;
}

/* .Egate_logo_with_Link {
  padding: 1rem 0;
} */
.Egate_landiing_ojnkl {
    display: flex;
    gap: 34rem;
}

.Egate_logo_hjurnfh h2 {
  padding-left: 9rem;
}

.Egate_logo_hjurnfh img {
  max-width: 350px;
}

.Egate_land_COntainer {
  max-width: 80%;
  margin: 0 auto;
}

.EGATE_eLanding_Container {
  padding: 3rem 0;
  /* background-color: #0f3d4f; */
}

.EGATE_landingE {
  display: flex;
  gap: 4rem;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.Egate_loge_landing img {
  width: 700px;
  background: #fff;
  box-shadow: -1px -1px 14px 2px #ffffff;
}
button.aboutusmain {
  padding: 1rem;
  border-radius: 13px;
  font-size: 15px;
  font-weight: 700;
}
.Egate_landiing_ojnkl h2 {
  font-size: 40px;
  /* padding: 2rem 0; */
  color: #000000;
}
.about-us-button {
  display: flex;
  padding: 10px 24px;
  font-size: 23px;
  color: white;
  text-align: center;
  text-decoration: none;
  background: linear-gradient(to bottom, #496db6, #357ABD);
  border: none;
  border-radius: 25px;
  /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2); */
  position: relative;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  font-weight: bold;
  background: linear-gradient(to bottom, #357ABD, #2d6db5);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.3);
  gap: 20px;
}
.about-us-button::after {
  content: '▶'; /* Right arrow */
  font-size: 20px;
  color: white;
  /* position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%); */
}



.Egate_landiing_ojnkl p {
  font-size: 22px;
  font-weight: 600;
  line-height: 37px;
  text-align: end;
  color: #c5d5dd;
}

.Egate_eLanding_Container {
  padding: 2rem 0;
}

.EGATE_eCordsLanding {
  display: flex;
  justify-content: space-around;
  /* flex-wrap: wrap; */
  gap: 1rem;
}

.Ug_egatePAge {
  /* background: rgb(255 218 218); */
  box-shadow: 0px 0px 12px 2px rgb(0 34 44);
  padding: 2rem;
}

.EGATE_elandUl h3 {
  background: rgb(0 61 78);
  text-align: center;
  color: rgb(255, 255, 255);
  padding-bottom: 0;
  word-spacing: 0.5rem;
  padding: 1.5rem;
}

.EGATE_elandUl {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.EGATE_elandUl li a {
  color: rgb(0, 0, 0);
  font-weight: bold;
}

.EGATE_elandUl li a span {
  margin: 0 0.5rem;
}

ul.EGATE_elandUl li {
  background: #e8e8e8;
  box-shadow: 0px 0px 5px -3px;
  text-align: center;
  display: flex;
  padding: 15px;
  font-size: 15px;
  border-radius: 25px;
  font-weight: 800;
}

.EgateHeader {
  background: #97bec7ff;
}

.EGATE_elandUl li:hover {
  background: rgb(235, 242, 244);
}

.Pg_buttonn {
  align-items: center;
  background-image: linear-gradient(135deg, #f34079 40%, #fc894d);
  border: 0;
  border-radius: 10px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-family: "Codec cold", sans-serif;
  font-size: 24px;
  font-weight: 700;
  height: 36px;
  justify-content: center;
  letter-spacing: 0.4px;
  line-height: 1;
  max-width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 3px;
  text-decoration: none;
  text-transform: uppercase;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.Pg_buttonn:active {
  outline: 0;
}

.Pg_buttonn:hover {
  outline: 0;
  background: rgb(42, 113, 132);
  color: rgb(255, 255, 255);
}

.Pg_buttonn span {
  transition: all 200ms;
}

.Pg_buttonn:hover span {
  transform: scale(0.9);
  opacity: 0.75;
}

@media screen and (max-width: 991px) {
  .Pg_buttonn {
    font-size: 15px;
    height: 35px;
  }

  .Pg_buttonn span {
    line-height: 50px;
    font-size: 15px;
  }
}

.button-89, .Ug_egatePAge-pg, .Ug_egatePAge {
  --b: 1px;
  --s: 0.45em;
  background-color: #d1d1d1;
  border-radius: 25px;
  /* --color: rgb(0 34 44); */
  padding: calc(0.5em + var(--s)) calc(0.9em + var(--s));
  color: var(--color);
  --_p: var(--s);
  /* background: conic-gradient(from 90deg at var(--b) var(--b),#0000 90deg,var(--color) 0)
  var(--_p) var(--_p)/calc(100% - var(--b) - 2*var(--_p)) calc(100% - var(--b) - 2*var(--_p)); */
  transition: 0.3s linear, color 0s, background-color 0s;
  outline: var(--b) solid rgb(0 34 44);
  outline-offset: -1px;
  box-shadow: 0px 0px 12px 2px rgb(0 34 44);
  font-size: 16px;
  border: 0;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.Ug_egatePAge-pg:hover,
.button-89:focus-visible {
  --_p: 0px;
  outline-color: var(--color);
  outline-offset: 0.05em;
  /* color: red; */
}

.Ug_egatePAge-pg:active {
  /* background: var(--color); */
  /* color: rgb(0 34 44); */
}
@media(max-width:320px){
  .about-us-button {
    font-size: 13px !important;
}
.Egate_landiing_ojnkl h2 {
  font-size: 12px !important;
}
.Pg_buttonn span {
  font-size: 18px !important;
}
}
@media(mix-width:320px){
  .about-us-button {
    font-size: 13px !important;
}
.Egate_landiing_ojnkl h2 {
  font-size: 12px !important;
}
.Pg_buttonn span {
  font-size: 18px !important;
}
}

@media only screen and (min-width: 1023px) and (max-width: 1280px) {
  .Egate_landing_header-e6578i {
    width: 72%;
    margin: 0 auto;
  }
  .Egate_landiing_ojnkl {
    display: flex;
    gap: 9rem;
}
  .Egate_logo_hjurnfh img {
    max-width: 257px;
  }

  .Egate_loge_landing img {
    max-width: 600px;
  }

  .Egate_landiing_ojnkl p {
    font-size: 16px;
    font-weight: 600;
    line-height: 35px;
    text-align: justify;
    color: #fff;
  }

  .EGATE_eCordsLanding {
    gap: 1rem;
  }

  .Egate_landing {
    width: 80%;
    margin: 0 auto;
  }

  .EGATE_landingE {
    gap: 3rem;
  }
}







@media only screen and (min-width: 600px) and (max-width: 1023px) {
  .Egate_logo_hjurnfh img {
    max-width: 260px;
  }
  .EGATE_eCordsLanding {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 4rem;
    margin: 1rem;
}
.Pg_buttonn span {
  transition: all 200ms;
  font-size: 18px;
}
.button-89, .Ug_egatePAge-pg, .Ug_egatePAge {
  --b: 1px;
  --s: 0.45em;
  background-color: #d1d1d1;
  border-radius: 25px;
  /* --color: rgb(0 34 44); */
  padding: calc(0.5em + var(--s)) calc(0.9em + var(--s));
  color: var(--color);
  --_p: var(--s);
  /* background: conic-gradient(from 90deg at var(--b) var(--b),#0000 90deg,var(--color) 0)
  var(--_p) var(--_p)/calc(100% - var(--b) - 2*var(--_p)) calc(100% - var(--b) - 2*var(--_p)); */
  transition: 0.3s linear, color 0s, background-color 0s;
  outline: var(--b) solid rgb(0 34 44);
  outline-offset: -1px;
  box-shadow: 0px 0px 12px 2px rgb(0 34 44);
  font-size: 16px;
  border: 0;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: 20rem;
  height: 25rem;
}
ul.EGATE_elandUl li {
  /* background: red; */
  box-shadow: 0px 0px 5px -3px;
  text-align: center;
  display: flex;
  padding: 15px;
  font-size: 15px;
}
  .Egate_landiing_ojnkl h2 {
    font-size: 30px;

}
.Egate_landing {
  width: 100%;
  margin: 0 auto;
}
.Egate_landiing_ojnkl {
  display: flex;
  gap: 2rem;
}
.Egate_loge_landing img {
  width: 440px;
  background: #fff;
  box-shadow: -1px -1px 14px 2px #ffffff;
}
  .footerdiv {
    /* gap: 9rem !important; */
}

  .EGATE_landingE {
    display: flex;
    gap: 4rem;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  ul.EGATE_elandUl li a {
    font-size: 15px;
  }
}

@media only screen and (max-width: 600px) {
  .Egate_logo_hjurnfh img {
    max-width: 120px;
  }
  ul.EGATE_elandUl li {
    text-align: left;
}
.button-89, .Ug_egatePAge-pg, .Ug_egatePAge {
  --b: 1px;
  --s: 0.45em;
  /* --color: rgb(0 34 44); */
  padding: calc(0.5em + var(--s)) calc(0.9em + var(--s));
  color: var(--color);
  --_p: var(--s);
  /* background: conic-gradient(from 90deg at var(--b) var(--b),#0000 90deg,var(--color) 0)
  var(--_p) var(--_p)/calc(100% - var(--b) - 2*var(--_p)) calc(100% - var(--b) - 2*var(--_p)); */
  transition: 0.3s linear, color 0s, background-color 0s;
  outline: var(--b) solid rgb(0 34 44);
  outline-offset: -1px;
  box-shadow: 0px 0px 12px 2px rgb(0 34 44);
  font-size: 16px;
  border: 0;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: 19rem;
}
ul.EGATE_elandUl li {
  font-size: 16px;
  font-weight: bold;
}
  .Egate_landing {
    width: 95%;
    margin: 0 auto;
}
.about-us-button {
  font-size: 16px;
}
.OurOfferings_btn {
  padding: 6px;
  font-size: 12px;
}
.Egate_landiing_ojnkl {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.Pg_buttonn span {
  line-height: 50px;
  font-size: 20px;
}
  ul.EGATE_elandUl li a {
    font-size: 10px;
  }

  .Egate_landiing_ojnkl p {
    font-size: 12px;
    font-weight: bold;
  }

  .Egate_landiing_ojnkl h2 {
    font-size: 15px;
   
}

  .Egate_logo_with_Link {
    /* padding: 0.5rem 0; */
  }
  .EGATE_eCordsLanding {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
}
  .Egate_logo_hjurnfh h2 {
    padding-left: 2rem;
    font-size: 8px;
  }

  .EGATE_landingE {
    display: flex;
    gap: 4rem;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .EGATE_landingE {
    gap: 2rem;
  }

 
  .Egate_landing_header-e6578i {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 85%;
    margin: 0 auto;
}
.Egate_loge_landing img {
  max-width: 281px;
  background: #fff;
  box-shadow: -1px 1px 20px 6px #ffffff;
  height: 88px;
}

  ul.EGATE_elandUl li a {
    /* background: red; */

    padding: 5px;
  }
  .button-89,
  .Ug_egatePAge-pg,
  .Ug_egatePAge {
    padding: 20px 6px;
  }
}

/* //landing_page_pop_up */
.landing_page_pop_up {
  position: absolute;
  top: 3%;
  right: 5%;
  padding: 6rem;
  /* width: 50%; */
  margin: 0 auto;
  background: #f1eded;
  box-shadow: -1px 1px 20px 6px #ffffff;
  border-radius: 18px;
  font-size: 2rem;
  z-index: 100;
}

.close_popup {
  position: absolute;
  top: 5%;
  right: 5%;
  cursor: pointer;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-size: 1rem;
  background: #000;
  color: #fff;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  margin: 0 auto;
  padding: 0 0.25rem;

  &:hover {
    color: red;
  }
}




.landingpage_footer{
  background: #000;
}

.landingpage_footer_icons{
  display: flex;
  gap: 2rem;
  justify-content: center;
  padding: 1rem;
  color: #fff;
}

.icon:hover{
  color: #00a3ff;
}






.landingpage_header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  margin: 0 auto;
  padding: 1rem 0;
}

.landingpage_logo img{
  max-width: 350px;
}

.landingpage_publications a{
  color: #000;
}

.landingpage_publications {
  align-items: center;
  appearance: none;
  background-color: #FCFCFD;
  border-radius: 4px;
  border-width: 0;
  box-shadow: #00aff0 0 2px 4px,rgba(45, 35, 66, 0.3) 0 7px 13px -3px,#D6D6E7 0 -3px 0 inset;
  box-sizing: border-box;
  color: #36395A;
  cursor: pointer;
  display: inline-flex;
  font-family: "JetBrains Mono",monospace;
  height: 48px;
  justify-content: center;
  line-height: 1;
  list-style: none;
  overflow: hidden;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
  text-align: left;
  text-decoration: none;
  transition: box-shadow .15s,transform .15s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  will-change: box-shadow,transform;
  font-size: 18px;
}

.landingpage_publications:hover {
  box-shadow: rgba(45, 35, 66, 0.4) 0 4px 8px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #D6D6E7 0 -3px 0 inset;
  transform: translateY(-2px);
}

.landingpage_div{
  background: #efefefff;
}

.landingpage_section{
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}


.landingsection_content h2{
  text-align: center;
  font-size: 2.5rem;
  color: #00a3ff;
}

.landingsection_content p{
  font-size: 1.5rem;
  line-height: 2rem;
}

.coursecards{
  background: #efefefff;
  box-shadow: #000 0 0 55px;
  padding: 1rem;
}

.landingpage_coursecards{
  display: flex;
  justify-content: space-evenly;
  width: 80%;
  margin: 0 auto;
  
}
.coursecard{
  margin-top: -10rem;
  background: #fff;
  padding: 2rem;
  text-align: center;
  box-shadow: #000 0px 1px 5px;
  
}

.coursecard ul{
  display: flex;
  flex-direction: column;
  gap: 1rem;
  
}

.coursecard li{
  padding: 1rem 0;
  font-weight: bold;
  font-size: 1.1rem;
  cursor: pointer;
  box-shadow: #000 0px 1px 2px;
  padding: 0.5rem;
}

.coursecard li:hover{
  box-shadow: #000 0px 1px 5px;
}

.coursecard h3{
  padding: 1rem 0;
  font-weight: bold;
  cursor: pointer;
  font-size: 2rem;
  background: #efefefff;
  color: #00a3ff;
}


footer {
  margin-top: 60px;
  background-color: #000;
  width: 100%;
  bottom: 0;
  /* padding: 1rem; */
}

.footer-container {
  /* padding: 0 5rem; */
  width: 80%;
  height: 25vh;
  margin: 0px auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.footer-container p {
  font-size: 0.9vmax;
  color: white;
  text-align: justify;
  margin: 0.5rem auto;
  /* margin-top:10px; */
  text-transform: none;
}

.col-1,
.col-2,
.col-3,
.col-4 {
  color: #ffff;
}

.col-2 a {
  color: #fff;
  font-size: 0.9vmax;
  display: flex;
  flex-direction: column;
  text-decoration-line: none;
  width: 55px;
}

.col-3 a {
  color: #fff;
  font-size: 0.9vmax;
  display: flex;
  flex-direction: column;
  text-decoration-line: none;
}

.foot-logo img {
  width: 36%;
  background-color: #fff;
  border-radius: 5px;
  border: 2px solid #01c3ff;
}

.col-1 {
  width: 22vmax;
}

.col-4 {
  width: 25vmax;
  font-size: 0.9vmax;
}

h6 {
  font-size: 1.2vmax;
  color: white;
  margin: 0;
}

footer a:hover {
  border-bottom: 4px solid rgb(129, 5, 5);
}

.fot-navlist {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

ins h6 {
  margin-top: 20%;
}

footer h6:hover {
  color: #01c3ff;
}

.fot-navlist1 p i {
  color: white;
  font-size: 0.8vmax;
  margin-right: 0.5rem;
  text-transform: none;
}

.fot-navlist1 p i:hover {
  color: #01c3ff;

  transform: rotateZ(45deg);
}

.fot-icons {
  width: 50%;
  display: flex;
  font-size: 1vmax;
  margin-top: 0vmax;
  color: #fff;
}

.fot-icons i {
  display: flex;
  /* justify-content: center;
    align-items: center; */
  height: 3vmax;
  width: 3vmax;
  margin-right: 0.5vmax;
  border-radius: 50%;
}

.fot-icons i:hover {
  transform: rotateZ(45deg);
  color: #01c3ff;
}

.copy {
  width: 90%;
  margin-top: 10px;
  border-top: 2px solid #fff;
}

.copy P {
  font-size: 0.8vmax;
  color: white;
  text-align: center;
  margin-top: 15px;
  text-transform: none;
}

.copy P:hover {
  color: #01c3ff;
}

.eLanding_Container {
  padding-left: 8%;
  padding-right: 8%;
}

.eCordsLanding {
  max-width: 80%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  /* flex-wrap: wrap;
    justify-content: space-between; */
  justify-content: space-between;
}
.eCordsLanding ul li a {
  color: black;
}

.elanLink {
  border: 1px solid rgb(0, 0, 0);
  border-radius: 0 30px;
  padding: 1.5rem;
}
.elandUl {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
}

.elandUl li {
  border: 1px solid red;
}
@media(min-width:1200px){
  .Ug_Home_page_main_container {
    width: 100%;
    margin: 0 auto;
    background: url(../ace-bg.jpg) no-repeat center center fixed;
    background-size: cover;
  }
  .Ug_Home_page_Sub_container {
    padding: 0rem 10%;
  }
  .Ug_Home_page_content_container {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px !important;
    background-color: #fff;
}
.Egate_landiing_ojnkl {
  display: flex;
  gap: 6rem;
}
}

@media(min-width:768px){
  ul.EGATE_elandUl li {
    /* background: red; */
    box-shadow: 0px 0px 5px -3px;
    text-align: center;
    display: flex;
    padding: 15px;
    font-size: 12px;
}
}

@media(min-width:600px){
  ul.EGATE_elandUl li {
    /* background: red; */
    box-shadow: 0px 0px 5px -3px;
    text-align: center;
    display: flex;
    padding: 15px;
    font-size: 12px;
}
}