/* new css */
.footermaindiv {
    width: 100%;
    background-color: #000;
    padding: 1rem 1rem 0rem 1rem;
    color: #fff;
    height: auto;
}

.footerlinksdiv a {
    font-weight: 600;
    
}
.footerterms a{
    color: #c4c6c7 !important;
}
.footerTitleCorporate {
    font-size: 20px !important;
}
.addressdiv{
    color: #c4c6c7;
}
/*  
a.OurOfferings_btn {
    display: none;
} */
.footerTitleColor2 {
    color: #279eff;
    font-size: 1.5rem;
    font-weight: 700;
    text-shadow: 2px 9px 8px #000;
}

.footerdiv {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 13rem;
    padding: 0.5rem 4rem;
}

.house-number {
    display: inline-block;
    /* Ensure it stays together */
    /* font-weight: bold; Optional: emphasize the house number */
}

/* .footeregradcontactus {
    margin-top: -2rem;
} */
/* .footeregradfollowus {
    margin-top: -8rem;
} */
.footeregraddiv {
    /* width: 30%; */
    display: flex;
    gap: 0.5rem;
    flex-direction: column;
    padding-bottom: 0.5rem;
}
.footerCopyright {
    text-align: center;
    color: #fff;
    border-top: 1px solid #c4c6c7;
    display: flex;
    gap: 15rem;
    /* justify-content: space-between; */
    padding: 3px 0px 0px 57px;
}

.footerlinksdiv1 svg {
    font-size: 1.5rem;
    text-align: center;
}
.footerlinksdiv1 a {
   text-decoration:  none;
}
.footerlinksdiv1 {
    display: flex;
    gap: 5px;
    align-items: center;
}

/* .footerlinksdiv {
    display: flex;
    flex-direction: column;
    gap: 1rem;
   
} */
.footericonsdiv {
    display: flex;
    gap: 1.5rem;
    font-size: 2rem;
    font-weight: 500;
}
.footermaindiv p{
    font-size: 15px;
    text-align: left;
}

.footerlinksdiv.footercontact {
    padding-top: 3rem;
    text-align: left;
}
.footeregradfollowus {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}
/*_____________MEDIA QUERIES FOR ALL SCREEN SIZES_____________ */
@media (max-width:768px) {
    .footerdiv {
        flex-direction: column;
        gap: 2rem;
        padding: 0rem 0rem;
    }
}

@media (max-width:600px) {
    .footermaindiv p {
        text-align: left !important;
        font-size: 16px;
    }
    .footermaindiv {
        width: 100%;
        background-color: #000;
        /* padding: 3rem 2rem; */
        color: #fff;
        height: auto;
    }
    .footerlinksdiv {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        gap: 0.5rem;
    }
    .footerdiv {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        /* gap: 10rem; */
        /* flex-direction: row; */
        padding: 0.5rem 1rem;
    }
    .footerCopyright {
        font-size: 21px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: left;
        gap: 0.5rem;
        padding: 1px 0px 0px 10px;
    }
    .footericonsdiv {
        display: flex;
        gap: 1.5rem;
        font-size: 1.5rem;
        font-weight: 500;
    }
    .footerlinksdiv.footercontact {
        padding-top: 0rem;
        text-align: left;
    }
    .footerlinksdiv a {
        font-weight: 600;
        color: #fff;
        font-size: 15px;
    }
    .footerTitleColor2 {
        color: #279eff;
        font-size: 1rem;
        font-weight: 700;
        text-shadow: 2px 9px 8px #000;
    }

    .footermaindiv {
        height: auto;
    }

    .footerCopyright p {
        text-align: center;
        /* padding: 0.5rem; */
        font-size: 15px;
    }

    .footeregraddiv {
        width: 100%;
        font-size: 13px;
    }

    .footericonsdiv {
        font-size: 20px;
    }

    .footerlinksdiv1 svg {
        font-size: 20px;
    }



    .footeregradcontactus {
        margin-top: 0rem;
    }

    .footeregradfollowus {
        margin-top: 0rem;
    }

    .footeregraddiv {
        font-size: 12px;
    }
}

@media (min-width:600px) and (max-width:768px) {
    .footerdiv {
        gap: 2rem;
    }
    .footermaindiv {
        width: 100%;
        background-color: #000;
        /* padding: 3rem 2rem; */
        color: #fff;
        height: auto;
    }
    .footerlinksdiv {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        gap: 0.5rem;
    }
    .footerdiv {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        /* gap: 10rem; */
        flex-direction: row;
        padding: 0.5rem 1rem;
    }
    .footerCopyright {
        text-align: center;
        color: #fff;
        border-top: 1px solid #c4c6c7;
        display: flex;
        gap: 2rem;
        align-items: center;
        /* justify-content: center; */
        padding: 7px 0px 0px 13px;
    }
    .footericonsdiv {
        display: flex;
        gap: 1.5rem;
        font-size: 1.5rem;
        font-weight: 500;
    }
    .footerlinksdiv.footercontact {
        padding-top: 2rem;
        text-align: left;
    }
    .footerTitleColor2 {
        color: #279eff;
        font-size: 1rem;
        font-weight: 700;
        text-shadow: 2px 9px 8px #000;
    }
}

@media(min-width:1200px) {
    .footerlinksdiv {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        gap: 0.5rem;
        align-items: flex-start;
    }
}

@media (min-width:992px) and (max-width:1200px) {
    .footerdiv {
        gap: 4rem;
    }

    .footerlinksdiv {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        gap: 0.5rem;
        /* text-decoration: underline; */
    }
    .footerdiv {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        gap: 10rem;
        /* flex-direction: column; */
        padding: 0.5rem 1rem;
    }
    .footerCopyright {
        text-align: center;
        color: #fff;
        border-top: 1px solid #c4c6c7;
        display: flex;
        gap: 12rem;
        /* justify-content: center; */
        padding: 7px 0px 0px 13px;
    }
}

@media (min-width:768px) and (max-width:992px) {
    .footerdiv {
        gap: 4rem;
    }

    .footerlinksdiv {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        gap: 0.5rem;
        /* text-decoration: underline; */
    }
    .footerdiv {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        /* gap: 10rem; */
        /* flex-direction: column; */
        padding: 0.5rem 1rem;
    }
    .footerCopyright {
        text-align: center;
        color: #fff;
        border-top: 1px solid #c4c6c7;
        display: flex;
        gap: 3rem;
        align-items: center;
        /* justify-content: center; */
        padding: 7px 0px 0px 13px;
    }
}